import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { logAnalyticsEvent, setAnalyticsUserProperties } from "@/constants/amplitude";
import { WebEventTypes } from "@/constants/event";
import { ExperimentIds, ExperimentVariants, TEST_VARIANTS } from "@/constants/experiments";
import { useLocalization } from "@/hooks/useLocalization";
import { localStorageManager, LocalStorageManagerKeys } from "@/utils/localStorageManager";
import { Sentry } from "@/utils/trackers";
import { GrowthBook, useGrowthBook } from "@growthbook/growthbook-react";
import { GrowthBookProvider as Provider } from "@growthbook/growthbook-react";

import { useGender } from "../GenderProvider";
import { useCurrency, useGeolocation } from "../GeolocationProvider";

import { FlowTypes } from "./types";

const growthBook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        setAnalyticsUserProperties({
            [result.featureId as string]: result.value,
            [experiment.key as string]: result.value,
        });
        logAnalyticsEvent(WebEventTypes.AB_TEST, {
            feature_flag: result.featureId,
            test_name: experiment.key,
            group_name: result.value,
        });
    },
});

export const getOrGenerateUuid = () => {
    let uuid = localStorageManager.getStoredItem<string>(LocalStorageManagerKeys.GB_UUID);

    if (!uuid) {
        uuid = uuidv4();
        localStorageManager.storeItem(LocalStorageManagerKeys.GB_UUID, uuid);
    }

    return uuid;
};

const GrowthBookProvider = ({
    children,
    isTurnedOff,
}: PropsWithChildren<{ isTurnedOff?: boolean }>) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const { gender } = useGender();
    const { currency, isLoaded } = useCurrency();
    const { language } = useLocalization();
    const { country } = useGeolocation();

    const init = async () => {
        if (isTurnedOff) {
            return;
        }
        const initResult = await growthBook.init({
            streaming: true,
            timeout: 1000,
        });
        if (initResult.error) {
            Sentry.captureException(initResult.error);
        } else {
            setIsInitialized(true);
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isInitialized && isLoaded) {
            const segment = growthBook.getFeatureValue(
                ExperimentIds.SEGMENTS,
                ExperimentVariants.NOT_ALLOCATED
            );
            growthBook.setAttributes({ ...growthBook.getAttributes(), segment });
        }
    }, [isInitialized, isLoaded]);

    useEffect(() => {
        if (isInitialized) {
            growthBook.setAttributes({
                ...growthBook.getAttributes(),
                user_id: getOrGenerateUuid(),
                gender,
                currency,
                language,
                country,
                route: window.location.pathname.replace(/\/$/, ""),
            });
        }
    }, [gender, currency, language, country, isInitialized]);

    return <Provider growthbook={growthBook}>{children}</Provider>;
};

export const useGrowthBookUpdateFlowType = (flowType?: FlowTypes) => {
    const growthBook = useGrowthBook();

    useEffect(() => {
        growthBook?.updateAttributes({
            flowType: flowType,
        });
    }, [flowType, growthBook]);
};

export const useExperiment = (experimentId: string) => {
    const growthBook = useGrowthBook();

    const variant = growthBook?.getFeatureValue(experimentId, null) as ExperimentVariants | null;

    return {
        variant,
        isTest: !!variant && TEST_VARIANTS.includes(variant),
        isExpLoaded: !!variant,
    };
};

export const useIsExperimentEnabledFunction = () => {
    const growthBook = useGrowthBook();

    const getIsExperimentEnabled = useCallback((experimentId: string) => {
        const variant = growthBook?.getFeatureValue(
            experimentId,
            null
        ) as ExperimentVariants | null;

        return !!variant && TEST_VARIANTS.includes(variant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { getIsExperimentEnabled };
};

export default GrowthBookProvider;
