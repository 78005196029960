import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

import GenderProvider from "@/utils/GenderProvider";
import GeolocationProvider from "@/utils/GeolocationProvider";
import GrowthBookProvider from "@/utils/providers/GrowthBookProvider";
import ThemeProvider from "@/utils/ThemeProvider";

import ErrorBoundary from "../common/ErrorBoundary";

export const BaseProviders = ({ children }: PropsWithChildren) => {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <GenderProvider>
                    <ThemeProvider>
                        <GeolocationProvider>
                            <GrowthBookProvider>{children}</GrowthBookProvider>
                        </GeolocationProvider>
                    </ThemeProvider>
                </GenderProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
};
