export const enum Routes {
    Both = "both",
    BothGoogle = "both_google",
    DynamicBoth = "dynamic-both",
    Flow = "main-flow",
    Loader = "loader",
    PromoPaywall = "paywall",
    Friends = "friends",
    NewYear = "newyear",
    ChallengesRedirect = "challenges-redirect",
    Age = "age",
    Calisthenics = "calisthenics",
    Offer = "offer",
    Funnel = "funnel",
    Seniors = "seniors",
    // routes with new select gender screens
    Lift = "lift",
    WeightLoss = "weightloss",
    Workout = "workout",
    Lift1 = "1lift",
    WeightLoss1 = "1weightloss",
    Workout1 = "1workout",
    AgeLift = "age-lift",
    AgeWeightLoss = "age-weightloss",
    AgeWorkout = "age-workout",
    Gender = "gender",
    Tiktok = "tt",
    // route to use in tiktok bio
    Liza = "liza",
}
