import { Routes as AppRoutes } from "@/constants/routes";

export const enum ChooseGenderScreenType {
    Lift = "lift",
    WeightLoss = "weightloss",
    Workout = "workout",
}

export const chooseGenderScreenTypeByRoute = {
    [AppRoutes.Lift]: ChooseGenderScreenType.Lift,
    [AppRoutes.Lift1]: ChooseGenderScreenType.Lift,
    [AppRoutes.AgeLift]: ChooseGenderScreenType.Lift,
    [AppRoutes.WeightLoss]: ChooseGenderScreenType.WeightLoss,
    [AppRoutes.WeightLoss1]: ChooseGenderScreenType.WeightLoss,
    [AppRoutes.AgeWeightLoss]: ChooseGenderScreenType.WeightLoss,
    [AppRoutes.Workout]: ChooseGenderScreenType.Workout,
    [AppRoutes.Workout1]: ChooseGenderScreenType.Workout,
    [AppRoutes.AgeWorkout]: ChooseGenderScreenType.Workout,
};

export const newBothContentRoutes = [
    AppRoutes.Lift,
    AppRoutes.Lift1,
    AppRoutes.AgeLift,
    AppRoutes.WeightLoss,
    AppRoutes.WeightLoss1,
    AppRoutes.AgeWeightLoss,
    AppRoutes.Workout,
    AppRoutes.Workout1,
    AppRoutes.AgeWorkout,
];

export const settingsByType = {
    [ChooseGenderScreenType.Lift]: {
        title: "Personal weightlifting program to boost your progress",
        subtitle: "Based on gender",
        className: "sm:bg-loaderBothLiftDesktop bg-loaderBothLift bg-top",
    },
    [ChooseGenderScreenType.WeightLoss]: {
        title: "Personal weight loss program",
        subtitle: "Based on gender",
        className: "sm:bg-loaderBothWeightLossDesktop bg-loaderBothWeightLoss bg-top",
    },
    [ChooseGenderScreenType.Workout]: {
        title: "Personal workout plan, based on gender",
        subtitle: "Select gender to start",
        className: "sm:bg-loaderBothWorkoutDesktop bg-loaderBothWorkout bg-top",
    },
};
